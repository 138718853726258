<template>
  <div class="book__popover">
    <div
      class="book__popover-head"
      :disabled="disabled"
    >
      <div
        class="book__popover-create-button"
        @click="openFilterSettings('new')"
      >
        <r-text>{{ $t('book-filters:create-new') }}</r-text>
      </div>
    </div>
    <div
      v-if="existingFilters.length"
      class="book__popover-wrapper"
    >
      <div class="book__popover-header">
        <r-title type="subtitle-2">
          {{ $t('book-filters:created') }}
        </r-title>
        <r-button
          simple
          type="primary"
          @click="beforeHandleFilter('', 'all')"
        >
          {{ toggleName }}
        </r-button>
      </div>
      <ul class="book__popover-list">
        <li
          v-for="(i, n) in existingFilters"
          :key="i.id"
          class="book__popover-item"
        >
          <el-checkbox
            :value="i.active"
            class="r-checkbox"
            size="mini"
            @change="beforeHandleFilter(i)"
          />
          <div
            class="book__popover-item-button"
            @click="beforeHandleFilter(i)"
          >
            <r-text v-if="i.name">
              {{ i.name }}
            </r-text>
            <r-text v-else>
              {{ $t('book-filters:default-name') }}{{ n + 1 }}
            </r-text>
            <div class="book-filter-item-info">
              <r-text type="caption">
                {{ $t('book-filters:sub-title') }}
              </r-text>
              <r-text
                type="caption"
                color-type="subhead"
              >
                {{ i.field }}
              </r-text>
              <r-text type="caption">
                {{ getFilterOpName(i.op) }}
              </r-text>
              <r-text
                type="caption"
                color-type="subhead"
              >
                {{ `'${i.value}'` }}
              </r-text>
            </div>
          </div>
          <div class="book__popover-tools">
            <div
              class="book__popover-tools-item"
              @click="openFilterSettings(i.id)"
            >
              <el-tooltip
                :open-delay="500"
                :content="`${$t('book-filters:tools-settings')}`"
                placement="bottom"
              >
                <r-icon
                  name="settings"
                  :size="20"
                />
              </el-tooltip>
            </div>
            <div
              class="book__popover-tools-item"
              @click="beforeCloseFilter(i.id)"
            >
              <el-tooltip
                :open-delay="500"
                :content="`${$t('book-filters:tools-close')}`"
                placement="bottom"
              >
                <r-icon
                  name="trash"
                  :size="20"
                  color-type="error"
                />
              </el-tooltip>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getFilterOpName } from './filter-types'

export default {
  data() {
    return {
      disabled: false,
      filtersObject: {}
    }
  },
  computed: {
    activeBook() {
      return this.$store.state.book.activeBook || {}
    },
    activeBookId() {
      return this.activeBook.id || null
    },
    columns() {
      return this.activeBook.fields || null
    },
    existingFilters() {
      return this.$store.state.book.filters.filtersConfigs
    },
    allFiltersTrue() {
      return this.existingFilters.find(e => !e.active)
    },
    toggleName() {
      return !this.allFiltersTrue
        ? this.$t('book-filters:clear-all')
        : this.$t('book-filters:apply-all')
    },
    changedRows() {
      return this.$store.state.tablePrime.changedRows.book_table || null
    },
    hasChanges() {
      if (!this.changedRows) return false
      return !!Object.values(this.changedRows)?.length
    }
  },
  methods: {
    beforeCloseFilter(id) {
      const warningText = this.hasChanges
        ? `${this.$t('modal.warning_text::cancel-with-changes')}`
        : `${this.$t('modal.warning_text::cancel')}`
      const warningTitle = `${this.$t('modal.warning_title')}`
      const confirmButtonText = `${this.$t('modal.confirm_button')}`
      const cancelButtonText = `${this.$t('modal.cancel_button')}`
      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        type: 'warning',
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.closeFilter(id)
        })
        .catch(() => {})
    },
    async closeFilter(id) {
      const isActive = this.existingFilters.find(e => e.id === id)?.active

      this.$store.commit('DELETE_TABLE_FILTER', id)

      await this.$store.dispatch('SAVE_MODULE_USER_CONFIG', {
        module: 'book',
        id: this.activeBookId
      })

      if (isActive) {
        this.$store.commit('UPDATE_ACTIVE_TABLE')
      }
    },
    openFilterSettings(id) {
      this.$store.commit('SET_TABLE_OPEN_FITLER', id)
      this.$store.commit('OPEN_MODAL_WINDOW', 'book-filter')
    },
    beforeHandleFilter(item, value) {
      if (this.hasChanges) {
        const title = 'Предупреждение'
        const message = 'Все изменения будут отменены. Продолжить?'
        const confirm = 'Подтвердить'
        const cancel = 'Отмена'
        this.$confirm(message, title, {
          customClass: 'r-message-box',
          type: 'warning',
          closeOnPressEscape: false,
          closeOnClickModal: false,
          confirm,
          cancel
        })
          .then(() => {
            if (value === 'all') {
              this.toggleAllFilter()
            } else {
              this.toggleFilter(item)
            }
          })
          .catch(() => {})
      } else {
        if (value === 'all') {
          this.toggleAllFilter()
        } else {
          this.toggleFilter(item)
        }
      }
    },
    async toggleFilter(item) {
      try {
        this.$store.commit('TOGGLE_TABLE_FILTERS', {
          id: item.id,
          value: !item.active
        })
        await this.$store.dispatch('SAVE_MODULE_USER_CONFIG', {
          module: 'book',
          id: this.activeBookId
        })
        this.$store.commit('UPDATE_ACTIVE_TABLE')
      } catch (e) {
        throw new Error(e)
      }
    },
    async toggleAllFilter() {
      try {
        this.$store.commit('TOGGLE_TABLE_FILTERS', {
          id: '',
          value: !this.allFiltersTrue,
          all: true
        })
        await this.$store.dispatch('SAVE_MODULE_USER_CONFIG', {
          module: 'book',
          id: this.activeBookId
        })
        this.$store.commit('UPDATE_ACTIVE_TABLE')
      } catch (e) {
        throw new Error(e)
      }
    },
    getFilterOpName
  }
}
</script>

<style lang="scss">
.book__popover {
  position: relative;
  padding: 8px;
  > div {
    &:nth-child(2) {
      margin-top: 16px;
    }
  }
  &-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-color: var(--dividers_low_contrast) !important;
  }
  &-create-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    width: 100%;
    padding: 8px;
    cursor: pointer;
    background-color: var(--button_secondary_bg) !important;
  }
  &-wrapper {
    padding: 0 0 8px;
    position: relative;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-list {
    margin-top: 16px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
  &-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--border-radius);
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
    &-button {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      * {
        margin-bottom: 2px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .r-text,
    .r-text.caption-2 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    &:hover {
      .book__popover-tools {
        display: flex;
      }
    }
  }
  &-tools {
    display: none;
    align-items: center;
    height: 100%;
    &-item {
      display: flex;
      align-items: center;
      border-radius: var(--border-radius);
      opacity: 1;
      cursor: pointer;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .book-filter-item-info {
    display: flex;
    width: 100%;
    > * {
      width: auto;
      margin-right: 4px;
      flex-shrink: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "book-filters:create-new": "Добавить новый фильтр",
    "book-filters:created": "Созданные фильтры",

    "book-filters:apply-all": "Включить все",
    "book-filters:clear-all": "Отключить все",

    "book-filters:default-name": "Фильтр ",
    "book-filters:sub-title": "Атрибут: ",

    "book-filters:tools-settings": "Настройки фильтра",
    "book-filters:tools-close": "Удалить фильтр",

    "modal.confirm_button": "Да",
    "modal.cancel_button": "Нет",
    "modal.warning_title": "Предупреждение",
    "modal.warning_text::cancel": "Фильтр будет удален. Продолжить?",
    "modal.warning_text::cancel-with-changes": "Фильтр будет удален, а все изменения будут отменены. Продолжить?"
  },
  "en": {
    "book-filters:create-new": "Create new filter",
    "book-filters:created": "Existing filters",

    "book-filters:apply-all": "Apply all",
    "book-filters:clear-all": "Clear all",

    "book-filters:default-name": "Filter ",

    "book-filters:sub-title": "Attribute: ",

    "book-filters:tools-settings": "Filter settings",
    "book-filters:tools-close": "Delete filter",

    "modal.confirm_button": "Yes",
    "modal.cancel_button": "No",
    "modal.warning_title": "Warning",
    "modal.warning_text::cancel": "The filter will be deleted. Continue?"
  }
}
</i18n>
