<template>
  <div class="book-content__edit">
    <div
      v-if="selectedObjects.length && !singleObject"
      class="book-content__edit-col"
    >
      <r-text>
        {{ `Выбрано объектов: ${selectedObjects.length}` }}
      </r-text>
    </div>
    <div
      v-if="selectedObjects && selectedObjects.length && hasEditor"
      class="book-content__edit-col"
    >
      <r-delete-button
        simple
        mini
        @delete="beforeDeleteObjects()"
      >
        {{ !singleObject ? 'Удалить выбранные' : 'Удалить' }}
      </r-delete-button>
    </div>
    <div
      v-if="
        selectedObjects && selectedObjects.length && hasEditor && !singleObject
      "
      class="book-content__edit-col"
    >
      <r-button
        icon="edit"
        @click="multiedit"
      >
        Редактировать
      </r-button>
    </div>
    <div
      v-if="singleObject"
      class="book-content__edit-col"
    >
      <r-button
        icon="info"
        @click="openObjectinfo"
      >
        Карточка объекта
      </r-button>
    </div>
    <div class="book-content__edit-saveblock">
      <r-table-search
        v-if="!isLoading"
        placeholder="Поиск по атрибуту"
        :filter-text="filterText"
        :filter-field="filterField"
        :list="attrList"
        @start-search="addFastFilter"
      />
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

export default {
  computed: {
    isLoading() {
      return this.$store.state.book.isLoading
    },
    activeBook() {
      return this.$store.state.book.activeBook || {}
    },
    iconsSourceId() {
      return this.$store.state.book.iconsSourceId
    },
    activeBookSourceId() {
      return this.activeBook.source_id || null
    },
    hasEditor() {
      return this.$store.getters.hasInstrument('Book', 'Editor')
    },
    selectedObjects() {
      return this.$store.state.tablePrime.selectedObjects.book_table || []
    },
    singleObject() {
      return this.selectedObjects.length === 1
    },
    attrList() {
      return (
        this.$store.state.book.activeFields?.fields
          ?.filter(l => l.type !== 'boolean')
          ?.map((e, i) => {
            return {
              id: i,
              title: e.title,
              value: e.origin_title || e.title,
              alias: e.alias || e.title,
              origin_title: e.origin_title,
              reflection: e?.reflection
            }
          }) || []
      )
    },
    fastFilter() {
      return this.$store.state.book.fastFilter || null
    },
    filterText() {
      return this.fastFilter?.filterText || ''
    },
    filterField() {
      return this.fastFilter?.filterField || ''
    },
    activeBookId() {
      return this.$store.state.book?.activeBook?.id || null
    }
  },
  methods: {
    openObjectinfo() {
      this.$store.commit('BOOK_TOGGLE_MODAL', true)
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'modalActive',
        value: this.selectedObjects[0]
      })
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'modalName',
        value: 'book-info'
      })
    },
    beforeDeleteObjects() {
      if (!this.selectedObjects?.length) return
      const warningText = `${this.$t('modal.warning_text::delete')}`
      const warningTitle = `${this.$t('modal.warning_title')}`
      const confirmButtonText = `${this.$t('modal.confirm_button')}`
      const cancelButtonText = `${this.$t('modal.cancel_button')}`

      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.deleteObjects(this.selectedObjects)
        })
        .catch(() => {})
    },
    async deleteObjects(selectedObjects) {
      const ids = selectedObjects.map(item => item.id).join(',')
      if (this.activeBookSourceId === this.iconsSourceId) {
        selectedObjects.forEach(e => this.deleteIcon(e.resource_id))
      }

      try {
        await this.$store.dispatch('DELETE_REQUEST', {
          url: `objectInfo/${this.activeBookSourceId}?id=${ids}`
        })

        const message = 'Удаление объекта выполнено успешно'
        this.$notify(notifyFactory('success', 'Удаление выполнено', message))
        this.$store.commit('UPDATE_ACTIVE_TABLE')
      } catch (e) {
        throw new Error(e)
      }
    },
    async deleteIcon(id) {
      if (!id) return
      try {
        await this.$store.dispatch('DELETE_REQUEST', {
          url: `objectResources/${this.iconsSourceId}/${id}`
        })

        const title = 'Удаление выполнено'
        const message = 'Удаление иконки выполнено успешно'
        this.$notify(notifyFactory('success', title, message))
      } catch (e) {
        throw new Error(e)
      }
    },
    multiedit() {
      this.$store.commit('BOOK_TOGGLE_MODAL', true)
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'modalName',
        value: 'book-edit'
      })
    },
    async addFastFilter(value) {
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'fastFilter',
        value
      })
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'update',
        value: true
      })
      try {
        await this.$store.dispatch('SAVE_MODULE_USER_CONFIG', {
          module: 'book',
          id: this.activeBookId
        })
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.book-content__edit {
  position: relative;
  display: flex;
  height: 36px;
  align-items: center;

  &-col {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  &-saveblock {
    display: flex;
    margin-left: auto;
    align-items: center;
    width: auto;

    .r-text {
      white-space: nowrap;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "tools:delete": "Удалить объекты",

    "modal.confirm_button": "Да",
    "modal.cancel_button": "Нет",
    "modal.warning_title": "Предупреждение",
    "modal.warning_text::cancel": "Изменения не будут сохранены. Продолжить?",
    "modal.warning_text::save": "Сохранятся все выполненные изменения. Продолжить?",
    "modal.warning_text::delete": "Объект будет удалён без возможности восстановления. Продолжить?"
  },
  "en": {
    "tools:delete": "Delete object",

    "modal.confirm_button": "Yes",
    "modal.cancel_button": "No",
    "modal.warning_title": "Warning",
    "modal.warning_text::cancel": "Changes will not be saved. Continue?",
    "modal.warning_text::save": "All changes made will be saved. Continue?",
    "modal.warning_text::delete": "The object will be permanently deleted. Continue?"
  }
}
</i18n>
