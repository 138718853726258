<template>
  <div class="book-content__tools">
    <div
      v-for="item in tools"
      :key="item"
      class="book-content__tools-item"
    >
      <el-tooltip
        :open-delay="1500"
        :content="`${$t(`tools:${item}`)}`"
        placement="top"
      >
        <r-button
          v-if="item === 'filter'"
          v-popover:bookFiltersPopover
          :type="existingFilters ? 'primary' : 'default'"
          :icon="item"
          dropdown
        >
          {{ `${$t(`tools:${item}`)}` }}
        </r-button>
        <r-button
          v-else-if="item === 'columns'"
          v-popover:bookSortingPopover
          :icon="item"
          dropdown
        />
      </el-tooltip>
    </div>
    <div
      class="book-content__tools-item devided-left"
      style="width: 100%"
    >
      <book-content-edit />
    </div>
    <el-popover
      ref="bookFiltersPopover"
      v-model="bookTableFilter"
      popper-class="r-popover simple"
      placement="bottom-start"
      width="300"
      trigger="click"
    >
      <book-table-filter @toggleFilter="toggleFilter" />
    </el-popover>
    <el-popover
      ref="bookSortingPopover"
      v-model="bookTableSort"
      popper-class="r-popover simple"
      placement="bottom-start"
      width="300"
      trigger="click"
    >
      <book-table-sort-tree />
    </el-popover>
  </div>
</template>

<script>
import bookTableFilter from '../book-filter/book-filter'
import bookTableSortTree from '../book-sort-tree/book-sort-tree'
import bookContentEdit from './book-content-edit.vue'

export default {
  components: {
    bookTableFilter,
    bookTableSortTree,
    bookContentEdit
  },
  data() {
    return {
      bookTableFilter: false,
      bookTableSort: false
    }
  },
  computed: {
    hasEditor() {
      return this.$store.getters.hasInstrument('Book', 'Editor')
    },
    hasFilters() {
      return this.$store.getters.hasInstrument('Book', 'Filters')
    },
    hasColumnsSettings() {
      return this.$store.getters.hasInstrument('Book', 'Columns Settings')
    },
    tools() {
      const tools = ['columns', 'filter']

      return tools.filter(e => {
        if (e.name === 'filters' && !this.hasFilters) return false
        if (e.name === 'sorting' && !this.hasColumnsSettings) return false
        return true
      })
    },
    existingFilters() {
      return !!this.$store.state.book.filters.filtersConfigs.find(e => e.active)
    },
    selectedObjects() {
      return this.$store.state.tablePrime.selectedObjects.book_table || null
    }
  },
  methods: {
    toggleFilter() {
      this.bookTableFilter = !this.bookTableFilter
    }
  }
}
</script>

<style lang="scss">
.book-content__tools {
  padding: 8px 16px;
  border-bottom: 1px solid;
  border-color: var(--dividers_low_contrast) !important;
  display: flex;
  align-items: center;
  height: 48px;
  &-item {
    padding: 0;
    position: relative;
    margin-left: 8px;

    &:first-child {
      padding-left: 0;
      margin-left: 0;
    }
    &.devided-left {
      padding-left: 13px;
      &:after {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
        left: 4px;
        background-color: var(--bg_containers) !important;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "tools:filter": "Все фильтры",
    "tools:columns": "Настройка отображаемых столбцов"
  },
  "en": {
    "tools:filter": "Filters",
    "tools:columns": "Customize display columns"
  }
}
</i18n>
